import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button'];
  static values = { url: String };

  changeLinkUrl(ev) {
    let params = new URLSearchParams(ev.detail.idsSelected.map(id => ['ids[]', id]));
    params.append('select_type', ev.detail.allSelected);

    this.buttonTarget.href = `${this.urlValue}?${params.toString()}`;
  }
}
