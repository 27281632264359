import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchInput', 'searchResults'];

  search() {
    let value = this.searchInputTarget.value.toLowerCase();
    Array.from(this.searchResultsTarget.getElementsByClassName('list-group-item')).forEach(item => {
      let exist = item.innerText.toLowerCase().indexOf(value) > -1;
      item.style.display = exist ? 'block' : 'none';
    });
  }
}
