import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    flag: Boolean
  }

  connect() {
    this.element.indeterminate = this.flagValue
  }
}
