import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox'];
  static values = { 'edit-url': String, id: String };

  beforeFetch(e) {
    TreeCategories.collectSelectedIds().forEach(item => e.detail.url.searchParams.append('selected_ids[]', item));
  }

  selectCategory() {
    let $checkbox = $(this.checkboxTarget);
    TreeCategories.toggleCheckboxRecursive($checkbox);

    let additionalParams = {
      'selected_ids[]': TreeCategories.collectSelectedIds(),
      'selected': $checkbox.prop('checked'),
      'selected_top_ids[]': TreeCategories.collectSelectedTopIds()
    };

    let editUrlValue = this.editUrlValue + '?' + $.param(additionalParams);
    fetch(editUrlValue, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
