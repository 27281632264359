import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['show']

  initialize() {
    this.globalClickHandler = (function (e) {
      this.hide()
    }).bind(this)
  }

  click(e) {
    e.stopPropagation()
  }

  show() {
    this.element.classList.add(this.showClass)
    document.addEventListener('click', this.globalClickHandler, { once: true })
  }

  hide() {
    this.element.classList.remove(this.showClass)
    document.removeEventListener('click', this.globalClickHandler)
  }

  isVisible() {
    return this.element.classList.contains(this.showClass)
  }

  toggle() {
    if (this.isVisible()) {
      this.hide()
    } else {
      this.show()
    }
  }

  markAllAsRead(e) {
    e.preventDefault()
    fetch(e.params.url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
      }
    })
  }
}
