import "bootstrap"
import "bootstrap-select"
import "../stylesheets/application"
import "../images/favicon_leafio.ico"
import "../images/logo_leafio.png"
import "../images/logo_navbar_leafio.png"
import "layout/src/img/add.svg"
import "layout/src/img/arrow-left.svg"
import "layout/src/img/arrow-right.svg"
import "layout/src/img/bell.svg"
import "layout/src/img/book.svg"
import "layout/src/img/caret-down.svg"
import "layout/src/img/caret-left.svg"
import "layout/src/img/caret-right.svg"
import "layout/src/img/caret-up.svg"
import "layout/src/img/close-gray.svg"
import "layout/src/img/close-red.svg"
import "layout/src/img/close.svg"
import "layout/src/img/comments-gray.svg"
import "layout/src/img/config-gray.svg"
import "layout/src/img/delete.svg"
import "layout/src/img/download.svg"
import "layout/src/img/edit.svg"
import "layout/src/img/error.svg"
import "layout/src/img/exit.svg"
import "layout/src/img/favicon.ico"
import "layout/src/img/file.svg"
import "layout/src/img/handshake-gray.svg"
import "layout/src/img/info.svg"
import "layout/src/img/logo.svg"
import "layout/src/img/options-gray.svg"
import "layout/src/img/orion-chart-gray.svg"
import "layout/src/img/pencil-gray.svg"
import "layout/src/img/profile.svg"
import "layout/src/img/sales-count.svg"
import "layout/src/img/sales-growth.svg"
import "layout/src/img/sales-product-sum.svg"
import "layout/src/img/search.svg"
import "layout/src/img/store-gray.svg"
import "layout/src/img/upload.svg"

// Notification icons
import "layout/src/img/notification-catalog.svg"
import "layout/src/img/upload-blue.svg"
import "layout/src/img/upload-green.svg"
import "layout/src/img/upload-red.svg"
import "layout/src/img/delete-gray.svg"

import { Tooltip } from 'bootstrap'
import { Turbo } from "@hotwired/turbo-rails"
import 'jquery-ui/ui/widgets/slider';
import Litepicker from 'litepicker';
import TreeCategories from './tree_categories';

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("controllers")

const { bottom } = require("@popperjs/core");

global.$ = global.jQuery = $;

window.addEventListener('turbo:before-fetch-request', () => {
    document.querySelectorAll('thead.sticky-top').forEach(thead => {
        thead.style.setProperty('top', '0px');
    });
});

window.addEventListener("turbo:load", () => {
    setTableListeners();

    addTitleTooltip();

    setTooltips();
});

function addTitleTooltip() {
    // Grab all tooltips from DOM
    var tooltips = document.getElementsByClassName('tooltip-ellipsis');

    // Loop through all tooltips
    Array.prototype.forEach.call(tooltips, function (currentTooltip) {

        if (isEllipsisActive(currentTooltip)) {
            // set text value for the title of current tooltip (DOM element)
            currentTooltip.title = currentTooltip.textContent;

            new Tooltip(currentTooltip)
        }
    });
}

function isEllipsisActive(e) {
    return (e.offsetWidth < e.scrollWidth);
}

function setTableListeners() {
    const table = document.querySelector('.table');
    const paginationInput = document.querySelector('.page-number__input');

    if (!table) {
        return;
    }

    // Sticky header
    function updateStickyHeaders(theads) {
        theads.forEach(function (el) {
            let y = el.parentElement.getBoundingClientRect().y
            el.style.setProperty('top', `${y < 0 ? -y : 0}px`)
        })
    }

    let theads = document.querySelectorAll('thead.sticky-top')
    let ticking = false
    document.addEventListener('scroll', function () {
        if (!ticking) {
            window.requestAnimationFrame(function() {
                updateStickyHeaders(theads)
                ticking = false
            })
            ticking = true
        }
    })

    if (window.scrollY > 0) {
        updateStickyHeaders(theads)
    }
}

function setTooltips() {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle=tooltip]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
        new bootstrap.Tooltip(tooltipTriggerEl, { delay: { show: 800 }, trigger: 'hover' });
    });
}
