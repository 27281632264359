import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startInput'];

  connect() {
    this.litePicker = new Litepicker({
      element: this.startInputTarget,
      showTooltip: false
    });
  }

  resetFilter() {
    this.startInputTarget.setAttribute('value', '');
    this.litePicker.clearSelection();
  }
}
