import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select'];

  connect() {
    $(this.selectTarget).selectpicker()
  }

  reset() {
    Array.from(this.selectTarget.options).forEach(option => option.removeAttribute('selected'));
    $(this.selectTarget).selectpicker('deselectAll');
  }

  disconnect() {
    $(this.selectTarget).selectpicker('destroy');
  }
}
