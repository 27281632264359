import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    modalId: String
  }

  click(e) {
    let modalElement = document.getElementById(this.modalIdValue)
    Modal.getOrCreateInstance(modalElement).show()
  }
}
