import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  markAsRead(e) {
    let formData = new FormData
    formData.append('notification[unread]', false)
    fetch(e.params.url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ notification: { unread: false } })
    })
  }
}
