import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'submit', 'cancel' ];
    static classes = [ 'hide' ];

    show() {
        this.submitTarget.classList.remove(this.hideClass)
        this.cancelTarget.classList.remove(this.hideClass)
    }

    hide() {
        this.submitTarget.classList.add(this.hideClass)
        this.cancelTarget.classList.add(this.hideClass)
    }

    enable(e) {
        this.submitTarget.disabled = false
    }
}
