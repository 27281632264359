import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchInput'];

  resetFilter() {
    this.searchInputTarget.value = '';
    this.searchInputTarget.setAttribute('value', '');
  }
}
