import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startInput', 'endInput', 'slider'];
  static values = { min: Number, max: Number, start: Number, end: Number };

  connect() {
    let startInputTarget = this.startInputTarget;
    let endInputTarget = this.endInputTarget;

    $(this.sliderTarget).slider({
      range: true,
      min: this.minValue,
      max: this.maxValue,
      values: [this.startValue, this.endValue],
      slide: function(event, ui) {
        startInputTarget.value = ui.values[0];
        endInputTarget.value = ui.values[1];
      }
    });
  }

  resetFilter() {
    let slider = $(this.sliderTarget);
    let options = slider.slider('option');
    slider.slider('values', [options.min, options.max]);

    this.startInputTarget.value = '';
    this.startInputTarget.setAttribute('value', '');
    this.endInputTarget.value = '';
    this.endInputTarget.setAttribute('value', '');
  }

  toggleRangePicker() {
    this.sliderTarget.parentNode.classList.toggle('d-none');
  }
}
