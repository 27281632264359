import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "checkbox", "submit"];
  static values = { url: String };

  addUrl(ev) {
    let url = new URL(this.urlValue, new URL(window.location));

    let params = url.searchParams
    params.append('select_type', ev.detail.allSelected)
    ev.detail.idsSelected.forEach(id => params.append("ids[]", id))
    let currentLocationParams = new URLSearchParams(window.location.search)
    currentLocationParams.forEach((value, key) => params.append(key, value))
    
    this.formTarget.action = url.toString();
  }

  enable({ currentTarget }) {
    let disable = !currentTarget.checked;
    this.toggleDisable(currentTarget.closest(".row").lastElementChild, disable);
  }

  toggleDisable(node, disable) {
    for (let element of node.children) {
      element.disabled = disable;
      element.classList.toggle("disabled", disable);
      if (element.hasChildNodes()) {
        this.toggleDisable(element, disable);
      }
    }
  }

  toggleSubmit(ev) {
    this.submitTarget.disabled =
      this.checkboxTargets.filter((checkbox) => checkbox.checked).length == 0;
  }
}
