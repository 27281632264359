import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['file', 'fileName', 'submit', 'error', 'chooseFileStep', 'uploadFileStep']
  static classes = ['hide']

  hideModal() {
    Modal.getInstance(this.element).hide()
  }

  toggleError(visible) {
    this.errorTarget.classList.toggle(this.hideClass, !visible)
  }

  submit(e) {
    e.preventDefault()
    this.submitTarget.disabled = true
    let form = this.fileTarget.form
    let data = new FormData(form)
    fetch(form.action, {
      method: 'POST',
      body: data
    }).then(resp => {
      if (resp.ok) {
        this.hideModal()
      } else {
        this.reset()
        this.toggleError(true)
      }
    })
  }

  update() {
    const fileSelected = this.fileTarget.files.length > 0
    this.submitTarget.disabled = !fileSelected
    this.chooseFileStepTarget.classList.toggle(this.hideClass, fileSelected)
    this.uploadFileStepTarget.classList.toggle(this.hideClass, !fileSelected)
    if (fileSelected) {
      this.fileNameTarget.textContent = this.fileTarget.files[0].name
    }
  }

  chooseFile() {
    this.update()
  }

  reset() {
    this.fileTarget.form.reset()
    this.update()
    this.toggleError(false)
  }

  dropFile(e) {
    e.preventDefault()
    this.fileTarget.files = e.dataTransfer.files;
    this.update()
  }

  dragOver(e) {
    e.preventDefault()
  }
}
