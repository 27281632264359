import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String };

  resetForm() {
    fetch(this.urlValue, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
