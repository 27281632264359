import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static classes = ["show", "hideBtn"];
    static targets = ["main", "count", "edit", "forecast", "massUpdate"];
    static values = { editUrlTemplate: String, forecastUrlTemplate: String, massUpdateUrlTemplate: String };

    connect() {
        this.ids = [];
    }

    show(ev) {
        this.ids = ev.detail.idsSelected
        this.mainTarget.classList.toggle(this.showClass, ev.detail.count > 0)
        if (ev.detail.count > 0) {
            document.addEventListener('turbo:submit-end', this.sendMessage)
        } else {
            document.removeEventListener('turbo:submit-end', this.sendMessage)
        }
    }

    showCount(ev) {
        this.countTarget.innerHTML = ev.detail.count;
    }

    disableEdit(ev) {
        if (ev.detail.count !== 1) {
            this.hasMassUpdateTarget ? this.editToMassUpdate() : this.editTarget.classList.add('disabled')
        } else {
            this.hasMassUpdateTarget ? this.massUpdateToEdit() : this.editTarget.classList.remove('disabled')
            this.editTarget.setAttribute('href', this.editUrlTemplateValue.replace('__id__', this.ids[0]))
        }
    }

    editToMassUpdate() {
        this.massUpdateTarget.classList.remove(this.hideBtnClass)
        this.editTarget.classList.add(this.hideBtnClass)
    }

    massUpdateToEdit() {
        this.massUpdateTarget.classList.add(this.hideBtnClass)
        this.editTarget.classList.remove(this.hideBtnClass)
    }

    forecastLink() {
        let url = new URL(this.forecastUrlTemplateValue, new URL(window.location));
        let searchParams = url.searchParams;
        this.ids.forEach(id => searchParams.append("promo_ids[]", id))
        this.forecastTarget.setAttribute('href', url)
    }

    clickForecast() {
        this.sendMessage()
    }

    sendMessage() {
        document.dispatchEvent(new CustomEvent("footer.close"));
    }
}
