import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["invalidPage"]
  static targets = ["page"]

  validatePage() {
    const pageValueRegexp = /^\d+$/
    return pageValueRegexp.test(this.pageTarget.value)
  }

  pageInput(e) {
    this.pageTarget.classList.toggle(this.invalidPageClass, !this.validatePage())
  }

  submit(e) {
    if (!this.validatePage()) {
      e.preventDefault()
    }
  }
}
