import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["selectAll", "selectAllPages", "checkbox", "mainCheckbox"];
    static classes = ["active"];
    static values = { totalCount: Number };

    connect() {
        this.hasCheckboxAllSelected = 'include';
    }

    sendMessage() {
        document.dispatchEvent(new CustomEvent("checkboxes.change", {
            detail: {
                count: this.count,
                allSelected: this.hasCheckboxAllSelected,
                idsSelected: this.ids
            },
            bubbles: true
        }));
    }

    applyStyles (checkbox) {
        checkbox.closest("tr").classList.toggle(this.activeClass, checkbox.checked)
    }

    applyPromoStyles (checkbox) {
        checkbox.closest(".extended-table-item").classList.toggle(this.activeClass, checkbox.checked)
    }

    setAllCheckboxes() {
        this.mainCheckboxTarget.checked = this.checkboxTargets.filter(checkbox => checkbox.checked).length < this.checkboxTargets.length;
        for (let checkbox of this.checkboxTargets) {
            checkbox.checked = this.mainCheckboxTarget.checked;
            this.applyStyles(checkbox)
        }
        this.sendMessage()
    }

    setOneCheckbox({ currentTarget }) {
        if (this.hasCheckboxTarget) {
            this.mainCheckboxTarget.checked = this.checkboxTargets.filter(checkbox => checkbox.checked).length > 0;
            this.applyStyles(currentTarget) }
        this.sendMessage()
    }

    setOneCheckboxWithoutMain({ currentTarget }) {
        if (this.hasCheckboxTarget) {
            this.applyPromoStyles(currentTarget) }
        this.sendMessage()
    }

    setAtAllPages() {
        this.mainCheckboxTarget.checked = this.count < this.totalCountValue;
        this.hasCheckboxAllSelected = this.mainCheckboxTarget.checked? 'exclude' : 'include';
        for (let checkbox of this.checkboxTargets) {
            checkbox.checked = this.mainCheckboxTarget.checked;
            this.applyStyles(checkbox)
        }
        this.sendMessage()
    }

    diselectAll() {
        for (let checkbox of this.checkboxTargets) {
            checkbox.checked = false;
            this.applyPromoStyles(checkbox)
        }
        this.sendMessage()
    }

    get count() {
        let count;
        if (this.hasCheckboxAllSelected === 'include') {
            count = this.checkboxTargets.filter(checkbox => checkbox.checked).length
        } else count = (this.totalCountValue - this.checkboxTargets.filter(checkbox => !checkbox.checked).length);

        return count
    }

    get ids() {
        let idsArray;
        if (this.hasCheckboxAllSelected === 'include') {
            idsArray = this.checkboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)
        } else idsArray = this.checkboxTargets.filter(checkbox => !checkbox.checked).map(checkbox => checkbox.value);

        return idsArray
    }
}