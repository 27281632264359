import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select'];

  connect() {
    $(this.selectTarget).selectpicker();
  }

  reset() {
    let selectTarget = $(this.selectTarget);
    selectTarget.selectpicker('val', selectTarget.find('option').first().val());
  }

  disconnect() {
    $(this.selectTarget).selectpicker('destroy');
  }
}
