import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static classes = ['show'];
    static targets = ["modal"];

    show(ev) {
        this.modalTarget.addEventListener(ev, this.modalTarget.classList.add(this.showClass))
    }
}
