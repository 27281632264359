export default class TreeCategories {
  static collectSelectedTopIds(listGroup = $('#tree-view > .list-group'), list = []) {
    listGroup.find('> .list-group-item').each(function(i, listGroupItem) {
      let $listGroupItem = $(listGroupItem);
      let $checkbox = $listGroupItem.find('> .parent .form-check-input');
  
      if ($checkbox.prop('checked')) {
        list.push($checkbox.data('id'));
      } else {
        let $nextListGroup = $listGroupItem.find('> .children .list-group').first();
        $nextListGroup.length && TreeCategories.collectSelectedTopIds($nextListGroup, list);
      }
    });

    return list;
  }

  static toggleCheckboxRecursive(checkboxTarget) {
    let checked = checkboxTarget.prop('checked');
    let checkboxContainer = checkboxTarget.closest('.parent');
    let parentCheckboxContainer = this.checkboxParentContainer(checkboxTarget);
    let allSiblingsAreChecked = this.allSiblingsAreChecked(checkboxTarget);
    let allClosestChildrenAreChecked = this.allClosestChildrenAreChecked(checkboxContainer);
    this[checked ? 'addSelectedId' : 'removeSelectedId'](checkboxTarget.data('id'));

    checkboxContainer.toggleClass('checked', checked);

    if (checked) {
      if (parentCheckboxContainer.length) {
        allSiblingsAreChecked && this.toggleParentCheckbox(parentCheckboxContainer, true);
        this.toggleCheckboxRecursive(parentCheckboxContainer.find('.form-check-input'));
      }

      this.toggleAllFurtherChildren(checkboxContainer, true);
    } else {
      if (parentCheckboxContainer.length) {
        this.toggleParentCheckbox(parentCheckboxContainer, false);
        this.toggleCheckboxRecursive(parentCheckboxContainer.find('.form-check-input'));
      }

      allClosestChildrenAreChecked && this.toggleAllFurtherChildren(checkboxContainer, false);
    }
  }

  static checkboxParentContainer(checkbox) {
    return checkbox.closest('[id*=tree-parent-container]').prev();
  }

  static allSiblingsAreChecked(checkbox) {
    let siblingCheckboxes = checkbox.closest('.list-group').find('> .list-group-item > .parent .form-check-input');
    if (!siblingCheckboxes.length) {
      return false;
    }

    return siblingCheckboxes.length == siblingCheckboxes.filter(':checked').length;
  }

  static allClosestChildrenAreChecked(checkboxContainer) {
    let closestChildrenCheckboxes = checkboxContainer.next('[id*="tree-parent-container-"]').find('.list-group').first()
      .find('> .list-group-item > .parent .form-check-input');
    if (!closestChildrenCheckboxes.length) {
      return false;
    }

    return closestChildrenCheckboxes.length == closestChildrenCheckboxes.filter(':checked').length;
  }

  static toggleParentCheckbox(parent, checked) {
    parent.toggleClass('checked', checked);
    parent.find('.form-check-input').prop('checked', checked);
  }

  static toggleAllFurtherChildren(checkboxContainer, checked) {
    let allChildrenContainers = checkboxContainer.next('[id*="tree-parent-container-"]')
      .find('.list-group-item .parent');
    allChildrenContainers.toggleClass('checked', checked);

    let allChildren = allChildrenContainers.find('.form-check-input');
    allChildren.prop('checked', checked);
  }

  static collectSelectedIds() {
    return $('#selected-ids-store .store').data('selectedIds');
  }

  static addSelectedId(id) {
    let selectedIds = this.collectSelectedIds();
    selectedIds.push(id);
    this.updateSelectedIds(selectedIds);
  }

  static removeSelectedId(id) {
    let selectedIds = this.collectSelectedIds();
    this.updateSelectedIds(selectedIds.filter(item => !(item == id)));
  }

  static updateSelectedIds(selectedIds) {
    $('#selected-ids-store .store').data('selectedIds', selectedIds.filter(this.onlyUnique));
  }

  static onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}

window.TreeCategories = TreeCategories;
